import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseDialog } from "./dialog";
import { bankUploadExplanation, batchExplanationCreate } from "@/service/weexpertService";
import { vatMap } from "../admin-page-components/admin-create-components/invoice-create-components/InvoiceItem";
import { BankTransactionForm } from "../forms/bank-transaction.from";
import { toast } from "../ui/use-toast";
import { Button } from "../ui/button";
import { explanationType, vatData } from "@/constants";
import { InputWithAlert } from "../ui/input-with-alert";
import { SelectWithAlert } from "../ui/select-with-alert";
import { useQueryClient } from "react-query";

export const BatchExplanationCreate = (props) => {
  const { ids } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [explanation, setExplanation] = useState('');
  const [vat, setVat] = useState('23%');
  const queryClient = useQueryClient()
  const onSubmit = () => {
    batchExplanationCreate(ids, vat, explanation, title)
      .then(() => {
        toast({
          title: t("alerts:toast_succes_contract_create_title"),
          description: t("alerts:toast_succes_invoice_create_description"),
        })

      })
      .catch(() => {
        toast({
          title: t("alerts:toast_error_counterparty_title"),
          description: t("alerts:toast_error_invoice_description"),
          variant: "destructive"
        })
      })
      .finally(() => {
        queryClient.invalidateQueries({ queryKey: ['report_____', 'transactions___']});
        setOpen(false)
      })
  }

  return <BaseDialog
    dialogTitle={""}
    buttonText={t('reports:create_explanation')}
    open={open}
    setOpen={setOpen}
  >
    <div className="w-[600px] pr-1 pl-1 ">
      <div className="w-full">
        <div >
          <InputWithAlert
            label={t('reports:explanation')}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            inputClassName={'h-8'}
          />
        </div>
      </div>
      <div className="pt-2">
        <SelectWithAlert
          label={t('invoices:positions_type_of_vat')}
          value={vat}
          options={vatData.map((option, index) => ({ value: option, label: option }))}
          onChangeValue={(e) => setVat(e)}
          inputClassName={'h-8 text-xs p-[4px] bg-white mt-1'}
        />
      </div>
      <div className="pt-2">
        <SelectWithAlert
          label={t('reports:explanation_type')}
          value={explanationType.value}
          options={explanationType.map((option, index) => ({ value: option, label: option }))}
          onChangeValue={(e) => setExplanation(e)}
          inputClassName={'h-8 text-xs p-[4px] bg-white mt-1'}
        />
      </div>

      <div className="w-full flex flex-col items-end">
        <Button className='mt-4'
          onClick={() => {
            onSubmit();
          }}
        >
          {t('button_add')}
        </Button>
      </div>
    </div>
  </BaseDialog>
}
